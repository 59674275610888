import parse from "html-react-parser";

import { getURL } from "@/utils/getURL";
import { GetReviewProps } from "./types";
import { getBody } from "../getBody";
import { axiosRequest } from "@root/src/hooks/axiosRequest";

export const getReview = ({ data, reviewHandler }: GetReviewProps) => {
  const url = getURL("api:custom-email-message-review");

  (async () => {
    try {
      const { data: response } = await axiosRequest({
        url,
        method: "post",
        data: {
          ...data,
          ...getBody({ data }),
        },
      });

      reviewHandler(parse(response));
    } catch (error) {
      console.error(error);
    }
  })();
};
