import { useState } from "react";

import { extractStringsInAngleBrackets } from "../../../utils/getReview/utils/extractStringsInAngleBrackets";

export const useSuggestions = (emails: string[], facilities: string[]) => {
  const [suggestions, setSuggestions] = useState<Record<string, any>[]>([]);

  const extractEmails = extractStringsInAngleBrackets(emails);

  const suggestionsHandler = (values: Record<string, any>[] | null) => {
    if (!values) return;
    setSuggestions(values);
  };

  const filteredSuggestions: Record<string, any>[] = suggestions.reduce<
    Record<string, any>[]
  >((acc, suggestion) => {
    const isActive: boolean = suggestion.is_active !== false;
    const isNotInFacilities: boolean = !facilities.includes(suggestion.id);
    const isNotExtractedEmail: boolean = !extractEmails.includes(
      suggestion.email
    );

    if (isActive && isNotInFacilities && isNotExtractedEmail)
      acc.push(suggestion);

    return acc;
  }, []);

  return { suggestions: filteredSuggestions, suggestionsHandler };
};
