import { useMemo } from "react";
import useSWR from "swr";

import { UseFetch, useFetchProps } from "./types";
import { validateUrl } from "./utils/validateURL";
import { useDelayedTableLoading } from "./hooks/useDelayedTableLoading";
import { PaginatedResponse } from "@/types";
import { axiosRequest } from "@root/src/hooks/axiosRequest";

// Axios fetcher to be used by SWR
async function axiosFetcher<T extends Record<string, unknown>>(
  url: string
): Promise<PaginatedResponse<T>> {
  validateUrl(url);
  const { data } = await axiosRequest<PaginatedResponse<T>>({ url });

  return data;
}

/**
 * Hook for fetching table records using SWR + Axios
 *
 * @param url - The constructed URL for fetching data
 * @param setIsTableLoading - Optional callback for setting loading state
 */
export const useFetch = <T extends Record<string, unknown> = {}>({
  url,
  setIsTableLoading,
}: useFetchProps): UseFetch<T> => {
  const DEFAULT_DATA: UseFetch<T> = {
    data: null,
    mutate: null,
    // count, next, previous are optional and omitted when not needed.
  };
  // Create the SWR key using relevant dependencies
  const swrKey = useMemo(() => url, [url]);

  const { data, error, isLoading, mutate } = useSWR<PaginatedResponse<T>>(
    swrKey,
    axiosFetcher
  );

  useDelayedTableLoading(isLoading, setIsTableLoading);

  // If SWR encountered an error (e.g. non-2xx response), `error` will be defined.
  // Construct a UseFetch<T> object with an appropriate error message.
  if (error)
    return {
      ...DEFAULT_DATA,
      data: {
        results: null,
        error: error.message,
      },
    };

  // If no error and no data, return the default structure (e.g., initial load)
  if (!data) return DEFAULT_DATA;

  // If we have data, return it as-is. `axiosFetcher` ensures `error` is always defined.
  return { data, mutate };
};
