import { ModalTemplateProps } from "@/components/Table/hooks/useModal/types";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { OrderActionModalProps } from "./types";
import { useSelectedOption } from "./hooks/useSelectedOption";
import { OrderHeader } from "./components/OrderHeader";
import { OrderDispositionForm } from "./components/OrderDispositionForm";
import { OrderTextArea } from "./components/OrderTextArea";
import { OrderButtons } from "./components/OrderButtons";
import { useOptions } from "../../../hooks/useOptions";

import "./scss/index.scss";

export const OrderActionModal = ({
  recordID: orderID,
  hasDispositionForm,
  header,
  url,
  ...props
}: ModalTemplateProps<OrderActionModalProps>) => {
  const options = useOptions(url, orderID);

  const { selectedOption, handleSelectedOption } = useSelectedOption();
  const lastOption: number | undefined = options.at(-1)?.value;
  const isMultiReason: boolean = selectedOption.reason === lastOption;

  return (
    <div className="orders-actions-modal-container">
      <OrderHeader {...{ header, orderID }} />
      <DropdownSingleSelect<number>
        options={options}
        header="Reason"
        selectedOption={selectedOption.reason as number}
        size="large"
        onClick={({ value }) => handleSelectedOption("reason", value)}
      />
      <OrderDispositionForm {...{ hasDispositionForm }} />
      <OrderTextArea
        {...{
          isMultiReason,
          notes: selectedOption.notes,
          handleSelectedOption,
        }}
      />
      <OrderButtons
        {...{
          ...props,
          isMultiReason,
          orderID,
          selectedOption,
        }}
      />
    </div>
  );
};
