import { toast } from "react-toastify";

import { getURL } from "@/utils/getURL";
import { SendEmailProps } from "./types";
import { getBody } from "../getBody";
import { axiosRequest } from "@root/src/hooks/axiosRequest";

export const sendEmail = ({ props, data, sendCopy }: SendEmailProps) => {
  const id = toast.loading("Uploading email");
  const url: string = getURL("api:custom-email-message-list");
  const autoCloseTime: number = 3000; //3 seconds
  props.onCloseModal();
  props.checkboxesHandler([]);

  (async () => {
    try {
      const { status } = await axiosRequest({
        url,
        method: "post",
        data: { ...data, ...getBody({ sendCopy, data }) },
      });

      const iValidResponse: boolean = status < 300 && status >= 200;

      iValidResponse
        ? toast.update(id, {
            render: "Email sent",
            type: "success",
            isLoading: false,
            autoClose: autoCloseTime,
            hideProgressBar: true,
            theme: "dark",
          })
        : toast.update(id, {
            render: "Email wasn't sent",
            type: "error",
            isLoading: false,
            autoClose: autoCloseTime,
            hideProgressBar: true,
            theme: "dark",
          });
    } catch (error) {
      console.error(error);
      toast.update(id, {
        render: "Email wasn't sent",
        type: "error",
        isLoading: false,
        autoClose: autoCloseTime,
        hideProgressBar: true,
        theme: "dark",
      });
    }
  })();
};
