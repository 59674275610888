import axios, { AxiosResponse } from "axios";

import { handleAxiosError } from "../utils/HandleAxiosError";
import { DEFAULT_HEADERS } from "./constants";
import { AxiosRequestProps } from "./types";

export const axiosRequest = async <Data = any, Response = any>({
  url,
  method = "get",
  data,
  config,
}: AxiosRequestProps<Data>): Promise<AxiosResponse<Response, any>> => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: DEFAULT_HEADERS,
      ...config,
    });

    return response;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
