import { CustomError } from "./types";

export const handleErrorWithResponse = (error: any) => {
  const { data, status, headers, statusText, config, message } = error.response;

  console.error("Response error:", {
    data,
    status,
    headers,
    config,
    statusText,
    message,
  });

  // Create the CustomError object
  const responseError: CustomError = {
    name: "CustomError",
    message:
      statusText ||
      "The request was made and the server responded with a status code that falls out of the range of 2xx",
    config,
    data,
    headers,
    status,
  };

  throw responseError;
};
