import { BuildFetchURLProps } from "./types";

export const buildFetchUrl = ({
  url,
  pageSize,
  page,
  input = "",
  filters,
  dateStatus,
  startDate,
  endDate,
  sortBy,
}: BuildFetchURLProps): string => {
  const offset = (page - 1) * pageSize;
  const params = new URLSearchParams();

  params.set("limit", pageSize.toString());
  params.set("offset", offset.toString());
  params.set("search", input);

  // Only set ordering if `sortBy` is provided
  if (sortBy) params.set("ordering", sortBy);

  // Add filters if present
  if (filters && filters.length > 0) {
    filters.forEach(({ header, subHeader: { value } }) => {
      // Convert filter keys and values to lowercase and encode them
      params.set(header.value.toLowerCase(), value);
    });
  }

  // Add date parameters if applicable
  if (startDate && endDate && dateStatus) {
    params.set(`${dateStatus}_after`, startDate);
    params.set(`${dateStatus}_before`, endDate);
  }

  return `${url}?${params.toString()}`;
};
