import { ChangeEvent } from "react";

import { PaginatedResponse } from "@/types";
import { axiosRequest } from "@root/src/hooks/axiosRequest";

export const fetchUsersEmails =
  (
    suggestionsHandler: (value: Record<string, any>[] | null) => void,
    url: string
  ) =>
  async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const value: string = event.target.value;
      if (!value) return suggestionsHandler([]);

      const {
        data: { results },
      } = await axiosRequest<PaginatedResponse>({
        url,
        config: {
          params: {
            search: value,
          },
        },
      });

      suggestionsHandler(results);
    } catch (error) {
      console.error(error);
    }
  };
