import { useMemo } from "react";

import { UsePagination, usePaginationProps } from "./types";

export const DOTS: string = "...";

/**
 * Custom hook for generating a pagination range
 * @param totalCount Total number of records
 * @param pageSize Number of records to display per page
 * @param siblingCount Number of sibling pages to display per page selection (default: 0)
 * @param currentPage Current pagination page
 *
 * @returns An array of page numbers and ellipses (...)
 *
 * Original code used from {@link https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/}
 */
export const usePagination = ({
  totalCount = 0,
  pageSize,
  siblingCount = 0,
  currentPage,
}: usePaginationProps): UsePagination => {
  const paginationRange: any = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    // If the number of pages is less than the page numbers we want to show, return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return Array(totalPageCount)
        .fill(0)
        .map((_, i) => i + 1);
    }

    // Calculate left and right sibling indices and make sure they are within range [1, totalPageCount]
    const leftSiblingIndex = Math.min(
      Math.max(currentPage - siblingCount, 1),
      totalPageCount
    );
    const rightSiblingIndex = Math.min(
      Math.max(currentPage + siblingCount, 1),
      totalPageCount
    );

    // Determine whether to show left and right dots
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = Array(leftItemCount)
        .fill(0)
        .map((_, i) => i + 1);
      return [...leftRange, -1, lastPageIndex];
    }

    // No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = Array(rightItemCount)
        .fill(0)
        .map((_, i) => totalPageCount - rightItemCount + i + 1);
      return [firstPageIndex, -1, ...rightRange];
    }

    // Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = Array(rightSiblingIndex - leftSiblingIndex + 1)
        .fill(0)
        .map((_, i) => leftSiblingIndex + i);
      return [firstPageIndex, -1, ...middleRange, -1, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  // Convert -1 values to "..."
  return paginationRange.map((p: number | string) => (p === -1 ? "..." : p));
};
