import { useEffect, useState } from "react";

import { UserType } from "./types";
import { getURL } from "@/utils/getURL";
import { axiosRequest } from "../axiosRequest";

export const useCurrentUser = (): UserType => {
  const [user, setUser] = useState<UserType>({
    user_id: 0,
    full_name: "",
    is_primary_contact: false,
    email: "",
    role: "",
    facility: {
      id: "",
      name: "",
    },
    is_active: false,
    permission_groups: [],
  });

  const url: string = getURL("api:internal-users-list") + "current/";

  useEffect(() => {
    try {
      const fetchUser = async () => {
        const { data } = await axiosRequest({ url });
        setUser(data);
      };

      fetchUser();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return user;
};
