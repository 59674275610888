import { FC } from "react";

import { useModal } from "@/components/Table/hooks/useModal";
import { OrderActionModal } from "../common/template/orderActionModal";
import { FlagOderProps } from "./types";
import { usePostOrderAction } from "../hooks/usePostOrderAction";

export const FlagOrder: FC<FlagOderProps> = ({ recordID }) => {
  const flagOrder = usePostOrderAction("api:internal-orders-flag");

  const { modal, handleOpenModal } = useModal(OrderActionModal, {
    recordID,
    header: "Flag Order",
    saveButtonLabel: "Flag this Order",
    url: "api:internal-orders-flag",
    hasDispositionForm: true,
    onClick: flagOrder,
  });

  return (
    <>
      <button className="drop-down-button" onClick={handleOpenModal}>
        Flag Order
      </button>
      {modal}
    </>
  );
};
