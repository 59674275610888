import { handleErrorWithResponse } from "./utils/handleErrorWithResponse";

export const handleAxiosError = (error: any) => {
  // Axios error handling convention
  // https://axios-http.com/docs/handling_errors

  //There was a error response, but the request was successful
  if (error.response) handleErrorWithResponse(error);

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    throw new Error("No response received from server");
  }

  // Something happened in setting up the request that triggered an Error
  if (error.message) throw new Error(error.message);

  throw new Error("Unexpected error occurred");
};
