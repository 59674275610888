import axios from "axios";
import { toast } from "react-toastify";

import { getURL } from "@/utils/getURL";
import { Serializer, SubmitPermissions } from "./types";
import { mapPermissions } from "./mapPermissions";
import { getHeaders } from "./utils/getHeaders";
import { TOAST_CONFIG } from "../../../../packageNotification/api/createPackageNotification/utils/createToastNotifier/constants";

export const submitPermissions = async <T extends Record<string, any>>({
  user_id,
  permissions,
  updateRecord,
  onCloseModal,
}: SubmitPermissions<T>) => {
  const requestBody: Serializer = {
    user_id: +user_id,
    groups: mapPermissions(permissions),
  };

  const url: string = getURL("api:internal-users-detail", { pk: user_id });
  const headers = getHeaders();

  try {
    await axios.patch(url, requestBody, { headers });
    toast.success("Permissions updated successfully", TOAST_CONFIG);
    updateRecord(permissions, "permission_groups", user_id);
  } catch (error) {
    console.error("Error submitting permissions:", error);
    toast.error("Permission update failed, please try again", TOAST_CONFIG);
  } finally {
    // Close the modal after operation
    onCloseModal();
  }
};
